.LoadingSpinner {
  font-size: 10px;
  text-indent: -99999px;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid rgba(0, 0, 0, 1);
  transform: translateZ(0);
  animation: spinAround 1.1s infinite linear;

  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
